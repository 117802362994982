import {
  TRANSACTION_OVERVIEW_PATH,
  TRANSACTIONS_PATH,
  RELATED_TRANSACTIONS_PATH,
  PAYOUTS_PATH,
  PAYOUT_OVERVIEW_PATH,
} from 'shared/paths/transactions'
import { Resource } from 'shared/permissions/constants'
import {
  TransactionsBrowsePage,
  TransactionDetailsPage,
  RelatedTransactionsPage,
  PayoutsBrowsePage,
  PayoutDetailsPage,
} from './pages'

export default [
  {
    title: 'Transactions',
    longTitle: 'Transactions',
    key: 'transactions',
    path: TRANSACTIONS_PATH,
    menu: true,
    component: TransactionsBrowsePage,
    resource: Resource.transactions,
  },
  {
    title: 'Transaction details',
    longTitle: 'Transaction details',
    key: 'transaction-details',
    path: TRANSACTION_OVERVIEW_PATH,
    menu: false,
    component: TransactionDetailsPage,
  },
  {
    title: 'Related transactions',
    longTitle: 'Related transactions',
    key: 'related-transactions',
    path: RELATED_TRANSACTIONS_PATH,
    menu: false,
    component: RelatedTransactionsPage,
  },
  {
    title: 'Payouts',
    longTitle: 'Payouts',
    key: 'transactions-payouts',
    path: PAYOUTS_PATH,
    menu: false,
    component: PayoutsBrowsePage,
    resource: Resource.transactions,
  },
  {
    title: 'Payout details',
    longTitle: 'Payout details',
    key: 'payout-details',
    path: PAYOUT_OVERVIEW_PATH,
    menu: false,
    component: PayoutDetailsPage,
  },
]
